/* eslint-disable react/no-danger */

const EMPTY_PATH = '/'

export const pagesCodes = {
    RECIPES: {
        pages: ['recepti', 'recept'],
        code: '15749'
    },
    INSPIRATION: {
        pages: ['inspiracija'],
        code: '15748'
    },
    BLOG: {
        pages: ['blog'],
        code: '15753'
    },
    COOLIE: {
        pages: ['coolie'],
        code: '15746'
    },
    DISHES: {
        pages: ['jela'],
        code: '15750'
    },
    PEOPLE: {
        pages: ['ljudi'],
        code: '15754'
    },
    INGREDIENTS: {
        pages: ['namirnice', 'namirnica'],
        code: '15751'
    },
    MAIN: {
        pages: [],
        code: '15745'
    },
    IMAGES: {
        pages: ['slike', 'slika'],
        code: '15752'
    },
    GREEN_CORNER: {
        pages: ['zeleni-kutak'],
        code: '15747'
    },
    OTHER: {
        pages: [],
        code: '380'
    }
}

export const DotMetricsPageCode = path => {
    const splitPath = path.split('/')
    const pageSlug = splitPath[1]
    const isGreenCorner =
        pagesCodes.INSPIRATION.pages.includes(splitPath[1]) && splitPath[2]?.includes(pagesCodes.GREEN_CORNER.pages[0])

    if (path === EMPTY_PATH) {
        return pagesCodes.MAIN.code
    }

    if (isGreenCorner) {
        return pagesCodes.GREEN_CORNER.code
    }

    const codeKey = Object.keys(pagesCodes).find(key => pagesCodes[key].pages.includes(pageSlug))
    return codeKey ? pagesCodes[codeKey].code : pagesCodes.OTHER.code
}
